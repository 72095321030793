'use strict'

const PropTypes = require('prop-types')
const React = require('react')
const RadioInput = require('../../inputs/radio')

const ActionList = require('@npm/design-system/action-list/action-list')
const TFAStatus = require('../../memberships/tfa-status')
const InputGeneric = require('../../inputs/generic')
const InputHidden = require('../../inputs/hidden')
const forms = require('../../../styles/forms.css')
const Checkbox = require('../../inputs/checkbox')
const Avatar = require('@npm/design-system/avatar/avatar')
const Form = require('../../forms/generic')
const types = require('../../../types')
const styles = require('./admin.css')

function inviteMaintainerForm(changeAction, formData) {
  return (
    <div>
      <h3 className={styles.heading}>Invite maintainer</h3>

      <Form
        className="mh0 mt3 mb4"
        method="POST"
        action={changeAction}
        formData={formData}
        buttonText="Invite"
        formId={'invite'}
      >
        <InputGeneric name="add" label="Username" initialValue="" formData={formData} />
      </Form>
    </div>
  )
}

function Admin(props) {
  const {
    pkg,
    maintainers,
    invitations,
    private: isPrivate,
    formData = {},
    canBePrivate,
    canAddMaintainers,
    canRemoveMaintainers,
    isPackageInviteFFEnabled,
    changeAction,
    isDeletable,
    deprecated,
  } = props

  const visibility = isPrivate ? 'private' : 'public'
  return (
    <div>
      <h2 className={styles.heading}>Package access</h2>
      <dl>
        <dt className={styles.defTitle}>Status:</dt>
        <dd className={styles.defDataStatus}>{visibility}</dd>
      </dl>

      <PackageSettingsForm
        canBePrivate={canBePrivate}
        action={changeAction}
        formId="package-settings"
        formData={formData}
      />

      {canAddMaintainers && inviteMaintainerForm(changeAction, formData)}
      {canAddMaintainers && isPackageInviteFFEnabled && (
        <ActionList type="Invitation" total={invitations.length} perPage={1000}>
          {invitations.map(invite => {
            const {user, avatars} = invite
            return (
              <div className={styles.listItem} key={`maintainer-${user.name}`}>
                <div className="flex-none">
                  <Avatar
                    size="small"
                    role="img"
                    title=""
                    aria-label={`${user.name} profile picture`}
                    src={avatars ? avatars.medium : ''}
                  />
                </div>
                <p className={styles.invitation}>{user.name}</p>
                {revokeInvitationButton(formData, user.name, changeAction)}
              </div>
            )
          })}
        </ActionList>
      )}

      <ActionList type="Maintainer" total={maintainers.length} perPage={1000}>
        {maintainers.map(maintainer => {
          const {user, permissions, grantor} = maintainer
          const {name, tfa, avatars} = user
          return (
            <div className={styles.listItem} key={`maintainer-${name}`}>
              <div className="flex-none">
                <Avatar
                  size="small"
                  role="img"
                  title=""
                  aria-label={`${name} profile picture`}
                  src={avatars ? avatars.medium : ''}
                />
              </div>
              <div className={styles.listItemTextContainer}>
                <p className={styles.maintainer}>{name}</p>
                <TFAStatus tfa={tfa} />
                <p className={styles.permissions}>{permissions} access</p>
                {grantor && maintainershipGrantor(grantor)}
              </div>
              {!grantor && canRemoveMaintainers && removeMaintainerButton(maintainers, changeAction, formData, name)}
            </div>
          )
        })}
      </ActionList>
      {!deprecated && (
        <React.Fragment>
          <h3 className={styles.heading}>Deprecate package</h3>
          <p>This will mark all versions of the package as deprecated.</p>
          <p>
            Please see npm&apos;s <a href="https://docs.npmjs.com/policies/unpublish">Unpublish Policy</a> for more
            info.
          </p>
          <a href={`/package/${encodeURIComponent(pkg)}/deprecate`} className={`${forms.buttonGradientRed} db`}>
            Deprecate package
          </a>
        </React.Fragment>
      )}

      {isDeletable && (
        <React.Fragment>
          <h3 className={styles.heading}>Delete package</h3>
          <p>Once you delete this package, you will lose access to it. Please be certain.</p>
          <a href={`/package/${encodeURIComponent(pkg)}/delete`} className={`${forms.buttonGradientRed} db`}>
            Delete package
          </a>
        </React.Fragment>
      )}
    </div>
  )
}

function PackageSettingsForm({action, formId, formData, canBePrivate}) {
  const values = [
    {
      value: 'tfa-not-required',
      label: "Don't require two-factor authentication",
    },
    {
      value: 'tfa-required-unless-automation',
      label: 'Require two-factor authentication or an automation or granular access token',
    },
    {
      value: 'tfa-always-required',
      label: 'Require two-factor authentication and disallow tokens',
    },
  ]

  return (
    <Form
      action={action}
      formId={formId}
      formData={formData}
      method="POST"
      className="ma0-ns"
      buttonText="Update Package Settings"
      buttonClassName={forms.buttonGradient + ' ' + forms.btnFitContent}
    >
      {canBePrivate && <Checkbox name="private" formData={formData} label={'Is Package Private?'} />}
      <h2 className={styles.heading}>Publishing access</h2>
      Requiring an additional authentication method adds another level of security for your package.
      <RadioInput
        name="publishingAccess"
        formData={formData}
        values={values}
        label=""
        fieldsetClassName={forms.fieldsetBgWhite}
      />
    </Form>
  )
}

Admin.propTypes = {
  maintainers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      access: PropTypes.string,
    }),
  ).isRequired,
  invitations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ),
  changeAction: PropTypes.string.isRequired,
  private: PropTypes.bool.isRequired,
  formData: types.formData,
  canBePrivate: PropTypes.bool,
  canAddMaintainers: PropTypes.bool,
  canRemoveMaintainers: PropTypes.bool,
  isPackageInviteFFEnabled: PropTypes.bool,
  isDeletable: PropTypes.bool,
  deprecated: PropTypes.bool,
}

Admin.defaultProps = {
  formData: {},
  canBePrivate: false,
  canAddMaintainers: false,
  canRemoveMaintainers: false,
}

function maintainershipGrantor(grantor) {
  let URL, description
  if (grantor && grantor.org) {
    if (grantor.team) {
      URL = `/settings/${encodeURIComponent(grantor.org)}/teams/team/${encodeURIComponent(grantor.team)}/users`
      description = `${grantor.team} team`
    } else {
      URL = `/settings/${encodeURIComponent(grantor.org)}/members`
      description = `${grantor.org} org`
    }
    return (
      <p className={styles.maintainershipGrant}>
        (via <a href={URL}>{description}</a>)
      </p>
    )
  }
}

function removeMaintainerButton(maintainers, changeAction, formData, name) {
  return (
    <Form
      className={styles.deleteForm}
      method="POST"
      action={changeAction}
      formData={formData}
      formId={`delete-${name}`}
      buttonText="×"
      buttonClassName={`${forms.deleteButton}`}
      buttonAriaLabel={`Remove maintainer ${name} from this package`}
    >
      <InputHidden name="remove" value={name} formId={`delete-${name}`} formData={formData} />
    </Form>
  )
}

function revokeInvitationButton(formData, userName, changeAction) {
  return (
    <Form
      className={styles.revokeForm}
      method="POST"
      action={changeAction}
      formData={formData}
      formId={`revoke-${userName}`}
      buttonText="×"
      buttonAriaLabel={`Revoke invitation for ${userName}`}
      buttonClassName={`${forms.deleteButton}`}
    >
      <InputHidden name="revoke" value={userName} formId={`revoke-${userName}`} formData={formData} />
    </Form>
  )
}

module.exports = Admin
