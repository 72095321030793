const React = require('react')
const formatBytes = require('pretty-bytes')
const orderBy = require('lodash/orderBy')
const SyntaxHighlighter = require('react-syntax-highlighter').default
const syntaxStyle = require('react-syntax-highlighter/dist/cjs/styles/hljs/github-gist').default
const getLanguageName = require('content-type-to-language-name')
const styles = require('./file-explorer.css')
const stylesV2 = require('./file-explorer-v2.css')
const forms = require('../../styles/forms.css')
const BackIcon = require('../icons/back')
// TODO: tweak svg's viewBox property to a sane default size
const FileIcon = require('../icons/file-explorer-file')
const FolderIcon = require('../icons/file-explorer-folder')
const Spinner = require('../spinner/spinner')

const FETCH_INDEX_RETRY_INTERVAL = 15000 // 15 seconds in milliseconds
const getSupportedLanguage = contentType => getLanguageName(contentType) || ''

// Changing the color of comment used by Syntax Highlighter to address color contrast accessibility issue
syntaxStyle['hljs-comment'].color = '#666666'

const isItemFolder = item => item && item.type === 'folder'

const TreeBreadcrumb = ({packageName, selectedFile, selectedFolder, onSelectFolder}) => {
  const selectedPath = selectedFile || selectedFolder
  const breadcrumbItems = selectedPath.split('/').filter(Boolean)
  const selectedAriaPath = `/${packageName}${selectedPath}`

  // TODO: root breadcrumb should not be a link when it's the unique item
  return (
    <div className={styles.treeBreadcrumb}>
      <h2 className={styles.srOnly}>{selectedAriaPath}</h2>
      <div className={styles.treeBreadcrumbsContainer}>
        <TreeBreadcrumbSeparator />
        {<TreeBreadcrumbItem path={'/'} label={packageName} onSelectFolder={onSelectFolder} />}
        {breadcrumbItems.map((item, i, arr) =>
          i === arr.length - 1 ? ( // is last item?
            <div className={styles.treeBreadcrumbTrailingItem} key={item} aria-hidden="true">
              {item}
              {selectedFolder ? ' /' : ''}
            </div>
          ) : (
            <TreeBreadcrumbItem
              key={item}
              path={`/${arr.slice(0, i + 1).join('/')}/`}
              label={item}
              onSelectFolder={onSelectFolder}
            />
          ),
        )}
      </div>
    </div>
  )
}

const TreeBreadcrumbItem = ({path, label, onSelectFolder}) => (
  <React.Fragment>
    <button onClick={() => onSelectFolder(path)}>{label}</button>
    <TreeBreadcrumbSeparator />
  </React.Fragment>
)

const TreeBreadcrumbSeparator = () => <span aria-hidden="true">/</span>

const TreeItem = ({entry, index, onSelectFolder, onSelectFile, autoFocus = false, focusRef}) => {
  const autoFocusButton = autoFocus || index === 0
  const focusRefButton = autoFocusButton ? focusRef : null

  return (
    <li className={styles.treeItem} style={{fontWeight: entry.type === 'folder' ? 'bold' : 'inherit'}}>
      <div className={styles.treeItemName}>
        {entry.type === 'folder' ? (
          <div className={styles.treeItemFolderIcon}>
            <FolderIcon />
          </div>
        ) : (
          <div className={styles.treeItemFileIcon}>
            <FileIcon />
          </div>
        )}
        {
          <button
            onClick={() => (isItemFolder(entry) ? onSelectFolder(entry.path) : onSelectFile(entry.path))}
            ref={focusRefButton}
          >
            {entry.label}
          </button>
        }
      </div>
      <div className={styles.treeItemInfo}>{entry.contentType}</div>
      <div className={styles.treeItemInfoSize}>{entry.size && formatBytes(entry.size)}</div>
    </li>
  )
}

const TreeView = ({
  currentFiles,
  selectedFolder,
  onSelectFolder,
  onSelectFile,
  autofocusFile = '',
  focusOnTreeView = false,
}) => {
  if (!currentFiles) return null
  let currIndex = 0

  const focusRef = React.createRef()

  React.useEffect(() => {
    if (focusRef.current && focusOnTreeView) {
      focusRef.current.focus()
    }
  }, [autofocusFile])

  return (
    <div>
      <ul className={styles.treeList}>
        {selectedFolder && selectedFolder !== '/' && (
          <TreeItem
            entry={{
              type: 'folder',
              path: getPrevPath({path: selectedFolder, isFolder: true}),
              label: '../',
            }}
            key="../"
            index={currIndex++}
            onSelectFolder={onSelectFolder}
            onSelectFile={onSelectFile}
            focusRef={focusRef}
          />
        )}
        {orderBy(
          Object.keys(currentFiles).map(currentPath => currentFiles[currentPath]),
          ['type', 'label'],
          ['desc', 'asc'],
        ).map(entry => {
          return (
            <TreeItem
              entry={entry}
              key={entry.path}
              index={currIndex++}
              onSelectFolder={onSelectFolder}
              onSelectFile={onSelectFile}
              autoFocus={autofocusFile === entry.path && focusOnTreeView}
              focusRef={focusRef}
            />
          )
        })}
      </ul>
    </div>
  )
}

const getPrevPath = ({path, isFolder}) =>
  `${path
    .split('/')
    .slice(0, -(isFolder ? 2 : 1))
    .join('/')}/`

const getselectedFolderSubTree = (files, selectedFolder) =>
  Object.keys(files).reduce((acc, filename) => {
    const name = filename && filename.replace(new RegExp(`^${selectedFolder}`), '')
    if (!name || name === filename) return acc

    const [currentFolderName, isFolder] = name.split('/')
    const {contentType, path, size, type} = files[filename]
    const label = isFolder ? `${currentFolderName}/` : name

    acc[label] = isFolder
      ? {
          contentType: 'folder',
          label,
          path: `${selectedFolder}${currentFolderName}/`,
          size: ((acc[label] && acc[label].size) || 0) + (size || 0),
          type: 'folder',
        }
      : {
          contentType,
          label,
          path,
          size,
          type,
        }

    return acc
  }, {})

const CodeView = ({currentFileContent, currentFileContentType, currentFileLOC, currentFileSize, onSelectBack}) => (
  <div className={styles.codeView}>
    <div className={styles.codeViewHeader}>
      <button autoFocus className={styles.codeViewBackButton} onClick={() => onSelectBack()}>
        <div className={styles.codeViewBackIcon}>
          <BackIcon />
        </div>
        Back
      </button>
      <div className={styles.codeViewHeaderFileInfo}>
        <div>{currentFileLOC} LOC</div>
        <div className={styles.codeViewHeaderItem}>{formatBytes(currentFileSize)}</div>
      </div>
    </div>
    <SyntaxHighlighter
      language={getSupportedLanguage(currentFileContentType)}
      showLineNumbers
      style={syntaxStyle}
      lineNumberStyle={{
        paddingLeft: 10,
        color: '#585858',
      }}
      tabIndex={0}
    >
      {currentFileContent}
    </SyntaxHighlighter>
  </div>
)

const getNoContentTitle = code =>
  ({
    NOPACKAGE: 'Setting up files...',
    NOPACKAGEFILE: 'This file failed to load',
    BINARYFILE: 'This file type is not supported',
    LARGEFILE: 'This file is too large to display',
    FILECOUNTLIMITEXCEEDED: 'This package is too large to display',
    FILESIZELIMITEXCEEDED: 'This package is too large to display',
    FILECORRUPT: 'This package cannot be loaded',
  })[code]

const NoContent = ({
  code,
  files,
  selectedFilePath,
  packageName,
  selectedFolder,
  fetchFiles,
  fetchSelectedFile,
  handleSelectFolder,
}) => {
  if (!code) return null
  const title = getNoContentTitle(code) || 'Oh noes! Something went wrong.'
  const filesNotFound = Object.keys(files).length === 0
  const onClickHandler = filesNotFound ? () => fetchFiles() : () => fetchSelectedFile(selectedFilePath)

  const isBinary = selectedFilePath && files[selectedFilePath].isBinary === 'true'
  const isLargeFile = selectedFilePath && files[selectedFilePath].fileUnprocessedReason === 'large'
  const errorCodes = new Set(['FILECOUNTLIMITEXCEEDED', 'FILESIZELIMITEXCEEDED', 'FILECORRUPT'])
  const isErrorCode = errorCodes.has(code)
  const showRetry = !isBinary && !isLargeFile && !isErrorCode

  return (
    <div>
      {!filesNotFound && (
        <TreeBreadcrumb
          packageName={packageName}
          selectedFolder={selectedFolder}
          selectedFile={selectedFilePath}
          onSelectFolder={path => handleSelectFolder(path)}
        />
      )}
      <div className={stylesV2.noContent}>
        <div className={stylesV2.Box}>
          <div className={stylesV2.blankslate}>
            {filesNotFound && !isErrorCode && (
              <div className={stylesV2.spinner}>
                <Spinner color="black" size="64" />
              </div>
            )}
            <h3 className={stylesV2['blankslate-heading']}>{title}</h3>
            {showRetry && (
              <div>
                {filesNotFound && (
                  <p className={stylesV2.noContentText}>
                    This happens only once for a package version and shouldn’t take long.
                  </p>
                )}
                {!filesNotFound && (
                  <p className={stylesV2.noContentText}>
                    Please try again or{' '}
                    <a className={stylesV2.noContentLink} href="https://npmjs.com/support">
                      contact us
                    </a>{' '}
                    if this issue persists.
                  </p>
                )}
                <div className={stylesV2['blankslate-action']}>
                  <button
                    onClick={onClickHandler}
                    className={`${forms.button} ${forms.buttonGradient} ${stylesV2.noContentButton}`}
                  >
                    {filesNotFound ? 'Refresh' : 'Try Again'}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

class FileExplorerV2 extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedFile: '',
      selectedFolder: '/',
      currentFileContent: '',
      files: {},
      loading: true,
      noContentCode: null,
      lastSelectedFile: '',
      focusOnTreeView: false,
    }
  }

  handleSelectFolder(path) {
    this.setState(prevState => {
      return {
        selectedFolder: path,
        selectedFile: '',
        currentFileContent: '',
        noContentCode: null,
        loading: false,
        lastSelectedFile: prevState.selectedFile || prevState.selectedFolder,
        focusOnTreeView: true,
      }
    })
  }

  handleSelectFile(path) {
    this.setState(prevState => {
      return {
        selectedFolder: null,
        selectedFile: path,
        loading: true,
        lastSelectedFile: prevState.selectedFile || prevState.selectedFolder,
        focusOnTreeView: true,
      }
    })
    this.fetchSelectedFile(path)
  }

  fetchSelectedFile = path => {
    const fileMetadata = path && this.state.files[path]
    const isBinary = fileMetadata && fileMetadata.isBinary === 'true'
    const isLargeFile = fileMetadata && fileMetadata.fileUnprocessedReason === 'large'
    if (isBinary) {
      this.setState({
        noContentCode: 'BINARYFILE',
      })
      return
    }
    if (isLargeFile) {
      this.setState({
        noContentCode: 'LARGEFILE',
      })
      return
    }

    const pkgName = this.props.packageName
    const fileHex = path && this.state.files[path] && this.state.files[path].hex
    const route = `/package/${pkgName}/file/${fileHex}`
    window
      .fetch(route)
      .then(response => {
        if (!response.ok) {
          this.setState({
            noContentCode: 'NOPACKAGEFILE',
          })
          throw new Error('Something went wrong.')
        }
        return response.text()
      })
      .then(result => {
        this.setState({
          currentFileContent: result,
          loading: false,
          noContentCode: null,
        })
      })
      .catch(() => {})
  }

  handleSelectBack() {
    this.setState(prevState => {
      return {
        selectedFolder: getPrevPath({path: this.state.selectedFile}),
        selectedFile: '',
        currentFileContent: '',
        loading: false,
        lastSelectedFile: prevState.selectedFile,
        focusOnTreeView: true,
      }
    })
  }

  componentDidMount() {
    this.fetchFiles()
  }

  componentWillUnmount() {
    clearInterval(this.intervalID)
  }

  fetchFiles = () => {
    const route = `/package/${this.props.packageName}/v/${this.props.packageVersion}/index`
    window
      .fetch(route)
      .then(response => {
        if (!response.ok) {
          this.setState({
            noContentCode: 'NOPACKAGE',
          })
          if (!this.intervalID) {
            this.intervalID = setInterval(() => {
              this.fetchFiles()
            }, FETCH_INDEX_RETRY_INTERVAL)
          }
          throw new Error('Something went wrong.')
        }
        return response.json()
      })
      .then(result => {
        if (result.errorCode) {
          this.setState({
            noContentCode: result.errorCode.toUpperCase(),
          })
          return
        }
        this.setState({
          files: result.files,
          loading: false,
          noContentCode: null,
        })
        clearInterval(this.intervalID)
      })
      .catch(() => {})
  }

  onBlurHandler(event) {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      this.setState({
        focusOnTreeView: false,
        lastSelectedFile: '',
      })
    }
  }

  render() {
    const {packageName} = this.props
    const {files, selectedFile, selectedFolder, loading, noContentCode, currentFileContent} = this.state
    const {integrity, shasum} = files
    const currentFiles = selectedFolder && getselectedFolderSubTree(files, selectedFolder)
    const currentFile = files[selectedFile]
    const currentFileContentType = currentFile && currentFile.contentType
    const currentFileLOC = currentFile && currentFile.linesCount
    const currentFileSize = currentFile && currentFile.size

    return (
      <div>
        <div className={styles.fileExplorer} onBlur={event => this.onBlurHandler(event)}>
          {!noContentCode && (
            <TreeBreadcrumb
              packageName={packageName}
              selectedFolder={selectedFolder}
              selectedFile={selectedFile}
              onSelectFolder={path => this.handleSelectFolder(path)}
            />
          )}
          {!selectedFile && !noContentCode && loading && (
            <div className={stylesV2.spinner}>
              <Spinner color="black" size="32" />
            </div>
          )}
          {currentFiles && !noContentCode && (
            <TreeView
              currentFiles={currentFiles}
              selectedFolder={selectedFolder}
              onSelectFolder={path => this.handleSelectFolder(path)}
              onSelectFile={path => this.handleSelectFile(path)}
              autofocusFile={this.state.lastSelectedFile}
              focusOnTreeView={this.state.focusOnTreeView}
            />
          )}
          {selectedFile && !noContentCode && loading && (
            <div className={stylesV2.spinner}>
              <Spinner color="black" size="32" />
            </div>
          )}
          {currentFileContent && !noContentCode && (
            <CodeView
              currentFileContentType={currentFileContentType}
              currentFileContent={currentFileContent}
              currentFileLOC={currentFileLOC}
              currentFileSize={currentFileSize}
              onSelectBack={() => this.handleSelectBack()}
            />
          )}
          {noContentCode && (
            <NoContent
              code={noContentCode}
              files={files}
              selectedFilePath={selectedFile}
              packageName={packageName}
              selectedFolder={selectedFolder}
              fetchFiles={() => this.fetchFiles()}
              fetchSelectedFile={path => this.fetchSelectedFile(path)}
              handleSelectFolder={path => this.handleSelectFolder(path)}
            />
          )}
        </div>
        <div className={styles.shasumInfo}>
          {integrity && (
            <div>
              <strong>Integrity:</strong> <div className={styles.shasumItem}>{integrity}</div>
            </div>
          )}
          {shasum && (
            <div>
              <strong>Shasum:</strong> <div className={styles.shasumItem}>{shasum}</div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

module.exports = FileExplorerV2
