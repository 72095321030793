'use strict'
const React = require('react')
const PropTypes = require('prop-types')
const connect = require('../../components/connect')
const styles = require('../../styles/package.css')
const {sparkline} = require('@fnando/sparkline')
const DownloadsIcon = require('../icons/downloads')

class Downloads extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {hoverData: null}
  }

  componentDidMount() {
    this.initSparkline()
  }

  componentDidUpdate() {
    // this.initSparkline()
  }

  initSparkline() {
    const svg = this.refs.sparkline
    const data = this.props.data.map(({downloads, label}) => ({date: label, value: downloads}))
    try {
      sparkline(svg, data, {
        onmousemove: (ev, datapoint) => {
          this.setState({hoverData: datapoint})
        },
        onmouseout: () => this.setState({hoverData: null}),
      })
    } catch (e) {
      console.error(e)
    }
  }

  render() {
    const {data} = this.props
    const {hoverData} = this.state

    return (
      <div className={`${styles.sidebarSection} w-100`}>
        <h3 className={`${styles.sidebarHeader} ${styles.downloads}`}>
          <DownloadsIcon />
          {hoverData ? hoverData.date : 'Weekly Downloads'}
        </h3>
        <div className={styles.sparklineContainer}>
          <svg
            className={styles.sparkline}
            ref="sparkline"
            width={200}
            height={40}
            strokeWidth="3"
            stroke="#8956FF"
            fill="rgba(137, 86, 255, .2)"
            aria-hidden={true}
          />
          <p className={styles.counter}>
            {hoverData ? hoverData.value.toLocaleString() : data[data.length - 1].downloads.toLocaleString()}
          </p>
        </div>
      </div>
    )
  }
}

Downloads.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      downloads: PropTypes.number,
      label: PropTypes.string,
    }),
  ).isRequired,
}

Downloads.defaultProps = {
  data: [{downloads: 0, label: 'unknown'}],
}

module.exports = connect()(Downloads)
